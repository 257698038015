<template>
  <div id="usage">
    <AcumenHeader/>
    <h2>Welcome</h2>
    <p>
      This website allows interaction with our metric that's described in the Paper
      "<b>Non-intrusive deep learning-based computational speech metrics with
      high-accuracy across a wide range of acoustic scenes</b>".
    </p>
    <p>
      There are 2 ways to interact with our metrics:
    </p>
    <ul>
      <li>Using the Drang and Drop interface below. This works well if you only have a
        few files to evaluate.
      </li>
      <li>By directly using the API endpoints. This is better if you have more files to
        evaluate.
      </li>
    </ul>
    <p>
      If you have questions, comments or suggestions about the API, please reach out
      via <a href="mailto:paul.meyer-rachner@audatic.ai">email</a>.
    </p>
    <br/>
    <TryIt/>
    <br/>
    <APIRestrictions/>
  </div>
</template>

<script>
// @ is an alias to /src
import AcumenHeader from '@/components/AcumenHeader.vue'
import APIRestrictions from '@/components/APIRestrictions.vue'
import TryIt from '@/components/TryIt.vue'

export default {
  name: 'HomeView',
  components: {
    AcumenHeader,
    TryIt,
    APIRestrictions
  }
}
</script>
<style>
#usage {
  padding: 20px;
  margin: auto;
  max-width: 800px;
}
</style>