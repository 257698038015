import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-code-highlight/themes/window.css'
import 'vue-code-highlight/themes/prism.css'

Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.prototype.$api_url = process.env.VUE_APP_API_URL

new Vue({
    router,
    render: function (h) {
        return h(App)
    },
    mounted() {
        document.title = process.env.VUE_APP_TITLE;
    },

}).$mount('#app')
