<template>
  <div id="acumen-header">
    <img alt="Audatic logo" src="../assets/logo.svg" style="max-width: 200px">
    <h1>Audatic Speech Quality Metric</h1>
  </div>
</template>

<script>
export default {
  name: 'AcumenHeader',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#acumen-header {
  text-align: center;
  padding-bottom: 20px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
