<!--With help from https://www.raymondcamden.com/2019/08/08/drag-and-drop-file-upload-in-vuejs-->

<template>
  <div id="try-it">
    <h2>Try it!</h2>
    <p>
      Drag the audio files into the box, then press the submit button and wait for the
      results to be displayed. Depending on the current load and number of files
      submitted, this may take between a few seconds and several minutes.
      Please note the restrictions that apply below.
    </p>
    <br/>
    <div v-cloak class="dropbox" @drop.prevent="addFile" @dragover.prevent>
      <h2 v-if="!files.length">Drag & Drop Audio</h2>
      <p v-for="file in files" :key="file.name">
        <b-button pill size="sm" title="Remove" variant="primary"
                  @click="removeFile(file)">X
        </b-button>
        {{ file.name }}
      </p>
    </div>
    <div class="submit">
      <b-button :disabled="uploadDisabled" variant="primary" @click="upload">Upload
      </b-button>
    </div>
    <h1>Result</h1>
    <div class="result pre-formatted">
      {{ message }}
    </div>
    <div class="result">
      <b-table :items="results" hover striped></b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "DragAndDrop.vue",
  data() {
    return {
      files: [],
      message: "No files submitted.",
      waiting_for_result: false,
      results: []
    }
  },
  computed: {
    uploadDisabled() {
      return (this.files.length === 0) || (this.waiting_for_result);
    }
  },
  methods: {
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });
    },
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
    },
    upload() {

      let self = this
      let url_submit = this.$api_url + "/submit/"
      let url_result = this.$api_url + "/result/"
      const updateResult = function (result) {
        // console.log("Received valid result", result)
        let status = result.status
        if (status === "SUCCESS") {
          for (const [file, metrics] of Object.entries(result["result"])) {
            for (const [mname, mval] of Object.entries(metrics)) {
              self.results.push(
                  {"file": file, "metric_name": mname, "metric_value": mval.toFixed(3)}
              )
            }
          }
          self.message = "File(s) successfully processed."
        } else {
          self.message = "There was an error while processing the files.\n\n" + result["error"]
        }
        self.waiting_for_result = false
      }

      const poll = async function (fn, fnCondition, ms) {
        let result = await fn();
        self.message = "Files submitted. Waiting for results. Submission status is " + result["status"] + "."
        while (!fnCondition(result)) {
          await wait(ms);
          result = await fn();
        }
        return result;
      };

      const wait = function (ms = 1000) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
      };

      const isTaskFinished = function (result) {
        return (result["status"] === "SUCCESS") || (result["status"] === "FAILURE")
      }

      let formData = new FormData();
      this.files.forEach((f) => {
        formData.append('files', f);
      });

      fetch(url_submit, {
        method: 'POST',
        body: formData
      })
          .then(res => {
            self.message = "File(s) submitted. Waiting for results."
            self.waiting_for_result = true
            self.results = []
            if (!res.ok) {
              return res.json().then(
                  res => {
                    throw new Error(res["detail"])
                  }
              )
            } else {
              return res.json()
            }
          })
          .then(async function (res) {
                let url = url_result + res["task_id"]
                const fetchResult = function () {
                  return fetch(url, {method: 'GET'}).then(res => res.json())
                }
                let result = await poll(fetchResult, isTaskFinished, 5000)
                updateResult(result)
              }
          )
          .catch(e => {
            try {
              console.error(JSON.stringify(e.message));
              self.message = e.message
            } catch (error) {
              console.error(error)
              self.message = "An unknown error occurred."
            }
            self.waiting_for_result = false
          });
    }
  },
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: 0;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.result {
  padding: 10px 10px;
}

.pre-formatted {
  white-space: pre-wrap;
}
</style>
