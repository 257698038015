<template>
  <div>
    <h2>API Restrictions</h2>
    <p>
      The API currently has the following restrictions:
    </p>
    <ul>
      <li>
        You can only submit single channel audio files (mono audio).
      </li>
      <li>
        Each audio snippets needs to be at least 4 seconds long and at most 20 seconds
        long.
      </li>
      <li>
        Snippets shorter than 4 seconds cannot be submitted.
        <b>
          Snippets that are longer
          than 4 seconds will be processed in 4 seconds chunks and the resulting
          predictions averaged.
        </b>
        If the duration of a file is not a multiple of
        4 seconds, the remainder will de discarded. For example, if you submit an
        audio file that is 6 seconds long, the returned score will be for the 4
        first seconds only. If you submit an audio file that is 8 seconds long, the
        returned score will the average of the predictions for seconds 0 to 4
        and 4 to 8.
      </li>
      <li>
        The number of snippets you can submit to the API within a give time window
        is limited. The current limit is 1 hours of audio submitted per 24 hours rolling
        window.
      </li>
      <li>
        You can submit at most 15 files per single request.
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "APIRestrictions.vue"
}
</script>

<style scoped>

</style>